import IoIosClose from "@/public/icons/IoIosClose.svg"

import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react"
import { toast } from "react-toastify"

import useFirebase from "@/lib/firebase"

import { useAuth } from "./auth.context"

interface INotification {
  id: string
  message: string
  read?: boolean
}

const NotificationsContext = createContext<{ notifications: INotification[] }>({
  notifications: [],
})

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { db } = useFirebase()
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [notsShown, setNotsShown] = useState<string[]>([])

  const { user } = useAuth()

  useEffect(() => {
    let unsubscribeFirestore: () => void

    if (user) {
      const loadFirebaseModules = async () => {
        const { collection, onSnapshot } = await import("firebase/firestore")

        unsubscribeFirestore = onSnapshot(
          collection(db, `notifications`, `${user.gid}`, "user-notifications"),
          (qs) => {
            const nots = qs.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            })) as INotification[]
            setNotifications(nots)
          },
        )
      }

      loadFirebaseModules()

      return () => {
        if (unsubscribeFirestore) {
          unsubscribeFirestore()
        }
      }
    }
  }, [user, db])

  useEffect(() => {
    if (!user) return

    notifications.forEach((not) => {
      if (notsShown.includes(not.id)) {
        return
      } else {
        setNotsShown((prev) => [...prev, not.id])
        toast.info(not.message, {
          autoClose: false,
          closeButton: ({ closeToast }) => {
            return (
              <button
                aria-label="Close notification"
                onClick={async (e) => {
                  const { deleteDoc, doc } = await import("firebase/firestore")
                  await deleteDoc(
                    doc(
                      db,
                      "notifications",
                      `${user.gid}`,
                      "user-notifications",
                      not.id,
                    ),
                  )
                  closeToast(e)
                }}
              >
                <IoIosClose className="w-4 h-4" />
              </button>
            )
          },
        })
      }
    })
  }, [notifications, user, db, notsShown])

  return (
    <NotificationsContext.Provider value={{ notifications }}>
      {children}
    </NotificationsContext.Provider>
  )
}
