import { ApolloClient, InMemoryCache } from "@apollo/client"

console.clear()
console.log({
  uri: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TEST_DOMAIN,
  "X-Shopify-Storefront-Access-Token":
    process.env.NEXT_PUBLIC_SHOPIFY_TEST_ACCESS_TOKEN!,
})
const client = new ApolloClient({
  uri: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_TEST_DOMAIN,
  cache: new InMemoryCache(),
  headers: {
    "X-Shopify-Storefront-Access-Token":
      process.env.NEXT_PUBLIC_SHOPIFY_TEST_ACCESS_TOKEN!,
  },
})

export default client
